.section-services {
  font-family: "Poppins", sans-serif;
  background-color: var(--secondary-color);
  color: #202020;
  padding-top: 30px;
  padding-bottom: 50px;
}

.section-services .header-section {
  margin-bottom: 34px;
}

.section-services .header-section .title {
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 22px;
}
.TitleFec {
  color: var(--primary-color);
  font-family: "Montserrat", sans-serif;
}

.section-services .header-section .title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--primary-color);
  border-radius: 3px;
}

.section-services .header-section .description {
  font-size: 12px;

  font-family: "Poppins", sans-serif;
}

.section-services .single-service {
  position: relative;
  margin-top: 30px;
  background-color: var(--white-text);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 10px;
  padding: 40px 30px;
  overflow: hidden;
}

.section-services .single-service .content {
  position: relative;
  z-index: 20;
}

.section-services .single-service .circle-before {
  position: absolute;
  top: 0;
  right: 0px;
  transform: translate(40%, -40%);
  width: 150px;
  height: 150px;
  background-color: var(--primary-color);
  border: 6px solid #504f93;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 10;
  transition: all 0.6s;
}

.section-services .single-service:hover .circle-before {
  width: 100%;
  height: 100%;
  transform: none;
  border: 0;
  border-radius: 0;
  opacity: 1;
}

.section-services .single-service .icon {
  display: inline-block;
  margin-bottom: 26px;
  width: 70px;
  height: 70px;
  background-color: var(--primary-color);
  border-radius: 5px;
  line-height: 70px;
  text-align: center;
  color: #fff;
  font-size: 30px;
  transition: all 0.3s;
}

.section-services .single-service:hover .icon {
  background-color: var(--fadeGreen-bg);
  color: var(--primary-color);
}

.section-services .single-service .title {
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 20px;
  transition: color 0.3s;
  font-family: "Montserrat", sans-serif;
}

.section-services .single-service:hover .title {
  color: #fff;
}

.section-services .single-service .description {
  margin-bottom: 20px;
  font-size: 12px;
  transition: color 0.3s;
  font-family: "Poppins", sans-serif;
}

.section-services .single-service:hover .description {
  color: #fff;
}

.section-services .single-service a {
  position: relative;
  font-size: 18px;
  color: #202020;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
}

.section-services .single-service:hover a {
  color: #fff;
}

.section-services .single-service a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--primary-color);
  transition: background-color 0.3s;
}

.section-services .single-service:hover a:after {
  background-color: #fff;
}
.computerIcon img,
.LibIconIcon img {
  height: 2rem;
  width: 2rem;
}

.traningIcon {
  height: 2.5rem;
  margin-left: 9px;
  width: 2.6rem;
}

/*---Image Marquee---*/

.ImageFacilitee {
  display: flex;
  height: 150px;
  overflow-x: hidden;
  cursor: pointer;
}

.marquee-content {
  display: flex;
  animation: scrolling 19s linear infinite;
}

.marquee-item {
  flex: 0 0 16vw;
  margin: 0 1vw;
  width: calc(100% / 3);
}

.marquee-item img {
  display: block;
  width: 100%;

  height: 100%;
  object-fit: cover;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}
/*----Facultie Deatails---*/
