.cont {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}

.contNum {
  display: flex;

  align-items: center;
  justify-content: flex-end;
  font-size: 12px;

  gap: 7px;

  img {
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }
  .MoText:hover,
  .PhText:hover {
    color: var(--pale-yellow-bg);
  }
}

.headerBar2 {
  position: relative;

  min-height: 8.9rem;

  width: 100%;
}
.English_hindibtn {
  display: flex;
  align-items: center;
}
.DateCUrrent {
  margin-top: 4px;
  font-size: 12px;
}

.English_hindibtn button {
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 12px;
}
.HindiBtn,
.EnglishBtn {
  padding-left: 7px;
  margin-right: 7px;
}
.HindiBtn button:hover,
.EnglishBtn button:hover {
  color: var(--pale-yellow-bg);
}
.LogO {
  padding-top: 12px;
  display: flex;

  position: relative;

  /* align-items: center; */
  height: auto;
  width: 100%;
  /* background-color: var(--secondary-color); */

  .LogOImg {
    padding-right: 8px;

    img {
      height: 4.6rem;
      width: 5.6rem;
      margin-left: 3rem;
    }
  }
}
.totalhits {
  position: absolute;
  z-index: 99;
  top: 4.7rem;
  right: 4.3rem;
  display: flex;
  flex-direction: row-reverse;
  width: 94%;
  padding-bottom: 0.3rem;
}
/*----About DropDown----*/

.dropdown-menu {
  display: none;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.nav-item:not(:hover) .dropdown-menu {
  display: none;
}
.programDrop {
  font-weight: 500;
  color: var(--nav-Txt) !important;
  font-size: 11px;
}

@media (max-width: 900px) {
  .totalhits {
    top: 5.9rem;
    right: 4.4rem;
  }
  .TotleHitsBttn {
    font-size: 15px !important;
  }
  .LogOImg img {
    height: 3rem;
    width: 4rem;
    margin-left: 0px !important;
  }
}

.TotleHitsBttnMobl {
  display: none;
}
@media (max-width: 686px) {
  .totalhits {
    display: none !important;
  }
  .TotleHitsBttnMobl {
    margin-top: 3px;
    font-size: 14px;
    display: block;
  }
}

/* Content */
.contentTeXt {
  display: flex;

  justify-content: space-between;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  position: relative;
  align-items: center;
  /* flex-direction: column; */

  h1 {
    font-size: 20px;
    color: var(--darkblue-text);
    text-shadow: 1px 1px 5px rgba(128, 125, 125, 0.31);
  }

  label {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-text);
    font-family: "Montserrat", sans-serif;
    margin: 0;
  }
}

.CallNumbs {
  z-index: 10;
  position: absolute;
  right: 5px;
  top: -16px;
}

/* Hero Section */
.heroSection {
  display: flex;
  background-color: var(--primary-color);
  justify-content: center;
  align-items: center;
  height: 2.7rem;
  width: 100%;

  .marquText a {
    color: var(--white-text);
    font-size: 13px;

    &:hover {
      color: var(--pale-yellow-bg);
    }

    @media (max-width: 700px) {
      font-size: 12px;
    }
  }
}

/*Marqueee*/
.heroContent {
  overflow: hidden;
  width: 100%;
}

.marqueeLink {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}

.marqueeText {
  display: inline-block;
  padding-right: 100%;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/*--------------NaVbar-----------*/

/* Navbar */
.navbar {
  margin-top: 0;
  padding-top: 0;
  z-index: 51;
}
.navbar-nav {
  .nav-link {
    padding-left: 0.5rem;
    border-radius: 20px;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
  }

  a {
    color: var(--primary-text);
    transition: all ease 0.4s;

    &:hover,
    &.hilite {
      font-size: 12px;
      background: var(--primary-color);
      color: var(--white-text) !important;
      border-bottom: 1px var(--blue-grey) solid;

      @media only screen and (min-width: 768px) {
        padding-left: 0;
      }
    }
  }
}
.RightSideData {
  display: flex;
  align-items: center;
  gap: 1rem;

  .social-icons {
    margin-top: 0 !important;
  }
}
/*----------New yar  Img--------------*/

.newYearImg {
  img {
    max-width: 95px;
    max-height: 95px;
  }
}
.navbar-nav .HomeIcon {
  transition: none !important;
}
@media only screen and (min-width: 768px) {
  .navbar-nav .HomeIcon {
    transform: none !important;
  }
  .LogOImg img {
    height: 2rem;
    width: 3rem;
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 999px) {
  .CallNumbs {
    display: none;
  }
  .totalhits {
    top: 5.7rem;
  }

  .RightSideData {
    flex-direction: column;
    gap: 0rem;
    .social-icons {
      display: none;
    }
  }
}
@media only screen and (max-width: 790px) {
  .totalhits {
    display: flex;
  }
}

@media only screen and (max-width: 610px) {
  .navbar-toggler {
    background-color: transparent;
    border: none;
    padding-left: 9px;
  }

  .navbar-toggler-icon {
    height: 20px;

    width: 17px;
    background-color: transparent;
    border: none;
  }
  .contentTeXt h1 {
    font-size: 15px;
  }
  .contentTeXt label {
    font-size: 9px;
  }
}
.rightDivHeader {
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
}
@media only screen and (max-width: 477px) {
  .contentTeXt h1 {
    font-size: 13px;
  }
  .contentTeXt label {
    font-size: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .contentTeXt {
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }
  .headerBar2 {
    min-height: 100%;
    margin-bottom: 0.5rem;
  }

  .rightDivHeader {
    display: flex;
    width: 100%;
  }
  .newYearImg {
    position: absolute;
    z-index: 300;
    top: 9.4rem;
    right: 0;
    img {
      overflow: hidden;

      height: 35px;
      width: 35px;
    }
  }
}
@media only screen and (max-width: 537px) {
  .newYearImg {
    top: 10.5rem;
  }
}
@media only screen and (max-width: 518px) {
  .newYearImg {
    top: 10.8rem;
  }
}
@media only screen and (max-width: 477px) {
  .newYearImg {
    top: 11.1rem;
  }
}
@media only screen and (max-width: 355px) {
  .newYearImg {
    top: 12.1rem;
  }
}
@media only screen and (max-width: 305px) {
  .newYearImg {
    top: 14.1rem;
  }
}
@media only screen and (max-width: 297px) {
  .newYearImg {
    top: 15.1rem;
  }
}

.navbar-nav .HomeIcon {
  transition: none !important;
}
.HomeIcon img {
  height: 16px;
  width: 20px;
}

/*programm com page */

.TableContainerCommitee {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  padding-top: 10px;
}
.progm {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}
.tbbbll th {
  font-family: "Montserrat", sans-serif;
  color: var(--white-text);
  background-color: var(--primary-color);
}
