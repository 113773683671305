/* Container styles */
.AboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

/* About content styles */
.list-style-one li a:hover {
  color: var(--primary-color);
}

.fadeInLeft {
  max-width: 100%;
}

.btn-style-one {
  display: inline-block;
  position: relative;
  font-size: 12px;
  color: var(--white-text);
  font-weight: 500;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: var(--primary-color);
  border-radius: 12rem;
}

.textTeXtPG3 p,
.textTeXtPG2 p,
.TeXtPG p {
  text-align: justify;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  color: var(--secondary-text);
}
.TeXtPG p {
  margin-top: 2rem;
}
.textTeXtPG3 p {
  margin-top: 3rem;
}

.textTeXtPG2 p {
  margin-top: 5rem;
}

.textTeXtPG2 p,
.textTeXtPG3 p {
  font-size: 12px;
  font-size: 12px;
}

.contentAboutt {
  padding: 0px;
}

@media (max-width: 1000px) {
  .textTeXtPG2 p {
    margin-top: 10px;
  }

  .about-section {
    position: relative;
    padding: 0 0 20px !important;
  }

  .about-section .image-column .inner-column:before {
    position: absolute;

    width: calc(50% + 80px);
    height: calc(100% + 90px) !important;
    top: -45px !important;
    left: -15px !important;
  }

  .about-section .sec-title {
    margin-top: 70px;
  }

  .about-section {
    position: relative;
    margin-top: 6rem;
  }

  .textTeXtPG2 p .textTeXtPG3 p {
    margin-top: 1.6rem;
    margin-left: 0.9rem;
  }
}

.btn-box a:hover {
  background-color: var(--lightNavy-bg);
  color: var(--grey-bg);
}

.about-section {
  position: relative;
  /* padding: 90px 0 70px; */
  padding-top: 10px;
}

.sec-title h1 {
  font-size: 24px;
  color: var(--primary-color);
  font-family: "Montserrat", sans-serif;
}

.about-section .sec-title {
  margin-bottom: 20px;
}

@media (min-width: 990px) and (max-width: 1201px) {
  .sec-title h1 {
    margin-left: 17px;
    font-size: 24px;
    color: var(--primary-color);
    font-family: "Montserrat", sans-serif;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .TeXtPG {
    margin-left: 20px;
  }
}

.about-section .btn-box a {
  font-family: "Montserrat", sans-serif;
  padding: 10px 23px;
}

.about-section .image-column .inner-column {
  position: relative;
  padding-left: 65px;
  padding-bottom: 0;
}

.about-section .image-column .inner-column:before {
  content: "";
  position: absolute;
  width: calc(50% + 80px);
  height: calc(100% + 90px);
  top: -45px;
  right: -45px;
  background: transparent;

  z-index: 0;
  border: 12px solid var(--primary-color);
}

.about-section .image-column .image-1 {
  position: relative;
}

@media (min-width: 990px) and (max-width: 3000px) {
  .image-1 {
    position: absolute;
    height: 24rem;
    width: 100%;
    /* left: 3.5rem; */
    top: 3rem;
  }
}

.fadeInLeft img {
  max-width: 100%;
  max-height: 100%;
}

.mb-10 {
  margin-bottom: 10px;
}

.articleDiractor2_dddd {
  margin-bottom: 73px;
}
