.syllbs h2 {
  padding-top: 23px !important;
}
.circle {
  padding: 13px 20px;
  border-radius: 50%;
  border: 3px solid #afbada;
  background-color: #213f99;
  color: #fff;

  max-height: 53px;
  z-index: 2;
}

.how-it-works.row .col-2 {
  align-self: stretch;
}
.how-it-works.row .col-2::after {
  content: "";
  position: absolute;
  border-left: 3px solid #afbada;
  z-index: 1;
}
.how-it-works.row .col-2.bottom::after {
  height: 50%;
  left: 50%;
  top: 50%;
}
.how-it-works.row .col-2.full::after {
  height: 100%;
  left: calc(50% - 3px);
}
.how-it-works.row .col-2.top::after {
  height: 50%;
  left: 50%;
  top: 0;
}

.timeline div {
  padding: 0;
  height: 40px;
}
.timeline hr {
  border-top: 3px solid var(--primary-color);
  margin: 0;
  top: 17px;
  position: relative;
}
.timeline .col-2 {
  display: flex;
  overflow: hidden;
}
.timeline .corner {
  border: 3px solid var(--primary-color);
  width: 100%;
  position: relative;
  border-radius: 15px;
}
.timeline .top-right {
  left: 50%;
  top: -50%;
}
.timeline .left-bottom {
  left: -50%;
  top: calc(50% - 3px);
}
.timeline .top-left {
  left: -50%;
  top: -50%;
}
.timeline .right-bottom {
  left: 50%;
  top: calc(50% - 3px);
}

.syllabusContent h5 {
  font-size: 13px;

  font-family: "Montserrat", sans-serif;
}

.syllabusContent h5:hover {
  color: var(--primary-color);
}
.syllabusContent {
  margin-bottom: 3rem;
}

.sllcont {
  max-width: 57rem;
}
