/*------Diractor------*/

.Diractorimg {
  margin-left: 10px;
  background-color: var(--white-text);
  border-radius: 20px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.21);
}
.Diractorimg img {
  /* margin-left: 10px; */
  height: 9rem;
  object-fit: fill;
  width: 10rem;
}
.DiractorSection {
  width: 100%;
  display: flex;
  position: relative;

  align-items: center;
  margin-top: 10px;
  padding: 10px 0;
}
.articleDiractor {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}
.articleDiractor h4 {
  font-size: 22px;
  color: var(--primary-color);

  font-family: "Montserrat", sans-serif;
}
.articleDiractor2,
.articleDiractor p,
.articleDiractor2 p {
  text-align: justify;
  padding-right: 10px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.articleDiractor2 {
  position: relative;
  padding-left: 10px;
}

@media (max-width: 1000px) {
  .DiractorSection {
    flex-direction: column;
  }
  .Diractorimg img {
    height: 6rem;
    width: auto;
  }
  .flex-center {
    flex-direction: column;

    align-items: center;
  }

  .Diractorimg {
    /* display: flex; */

    justify-content: center;
    align-items: center;
  }
  .Diractorimg img {
    object-fit: fill;
  }
  .articleDiractor {
    padding-top: 10px;
    text-align: center;
  }
  .teamMemsAdministr {
    display: flex;

    justify-content: center;
  }
}

.StaffContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
}

.team-members {
  margin-top: 0px;
  padding: 0px;
  /* transform: rotate(-45deg); */
  list-style-type: none;
}
.team-members li > div {
  float: left;
  width: 15.4%;

  height: auto;
}

.team-members li:nth-child(2) > div:first-child {
  margin-left: 23%;
}

.team-members li:last-child > div:first-child {
  margin-left: 40%;
}
.member-details {
  position: relative;
  display: flex;

  padding: 1px;
}
.member-details > div {
  background-color: #ddd;
  background: center;
  margin: 5px;
}
.member-details img {
  /* transform: rotate(45deg) translate(0, 15px) scale(1.2); */

  max-width: 100%;
  height: 100%;
  object-fit: fill;

  display: block;
}
.FaculTi,
.FaculTi2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  height: 130px;
  position: relative;
}
.FaculTi img,
.FaculTi2 img {
  height: 100px;
  width: 100px;
}

/* hover content - style */
.member-details > div {
  position: relative;
  overflow: hidden;
}

.member-info {
  position: absolute;
  top: 49%;
  /* transform: rotate(45deg) translate(-12px, 15px); */
  left: 10px;
  right: 0;
  z-index: 2;
  text-align: center;
}

.member-info h3,
.member-info p {
  position: absolute;

  font-size: 10px;
  margin: 0;
  color: #fff;
  position: relative;
  opacity: 0;
  visibility: hidden;
}
.member-info p {
  font-size: 8px !important;
}

.member-info h3 {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400;
  background-image: linear-gradient(
    45deg,
    rgba(81, 141, 176, 0.8) 50%,
    transparent 100%
  );

  /* top: -100px; */
}

.member-info p {
  font-weight: 300;
  font-size: 9px;
  bottom: -150px;
  background-image: linear-gradient(
    45deg,
    rgba(81, 141, 176, 0.8) 50%,
    transparent 100%
  );
}

/* .member-details > div:after {
  content: "";
  background-image: linear-gradient(
    45deg,
    rgba(81, 141, 176, 0.8) 50%,
    transparent 100%
  );
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
 
} */
.marquee {
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  white-space: nowrap;
  animation: marquee 18s linear infinite;
}
.marquee:hover .marquee-content {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.member-details *,
.member-details > div:after {
  cursor: pointer;
  transition: all 0.4s ease;
}

.member-details:hover *,
.member-details:hover > div:after {
  opacity: 1;
  visibility: visible;
}

.member-details:hover .member-info h3 {
  top: 10;
}

.member-details:hover .member-info p {
  bottom: 0;
}

/* Team overview */
.tabl {
  margin-left: 2rem;
  margin-top: 20px;
  border: 0.2px solid var(--primary-color);
}
.teamMemsAdministr h2 {
  color: var(--primary-color);
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 5px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.team-overview > a {
  margin-bottom: 10px;
  display: block;
}

.team-overview > a:before {
  content: "";
  width: 10px;
  height: 2px;
  position: relative;
  top: -3px;
  margin-right: 5px;
  background-color: #ccc;
  display: inline-block;
}

.flex-center {
  margin-top: 1.1rem;
  display: flex;

  justify-content: center;
  align-items: center;
}

.flex-center > div:first-child {
  order: 2;
}

@media only screen and (max-width: 550px) {
  .team-members li {
    text-align: center;
  }
  .team-members li > div {
    float: none;
    display: inline-block;
    width: 100%;
    margin: 0 !important;
  }
  .team-members {
    transform: rotate(0);
  }
  .member-details img {
    transform: rotate(0) translate(0, 0);
  }
}

@media only screen and (max-width: 399px) {
  .team-members li > div {
    max-width: 100%;
  }
}

/*----our team--*/
.FaultiText {
  display: flex;
  justify-content: center;
}
.AdminFacult {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.AdminFacult h3,
.FaultiText h3 {
  padding-top: 13px;
  padding-bottom: 8px;
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  color: var(--primary-color);
}

.team-section {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.team-member {
  text-align: center;
  margin: 20px;
  padding: 20px;
  background-color: var(--white-text);
  border-radius: 10px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.21);
  transition: transform 0.3s ease-in-out;
}
.teamMemsAdministr ul {
  padding: 0px;
}

.team-member:hover {
  transform: translateY(-10px);
}

.team-member img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: fill;
  margin-bottom: 10px;
}

.team-member h3,
.table-responsive th {
  font-family: "Montserrat", sans-serif;

  font-weight: 500;
  margin: 0;
  font-size: 15px;
}

.team-member .role-2 {
  font-family: "Poppins", sans-serif;
  font-size: 8px;
  color: var(--light-grey-text);
  margin-bottom: 0px;
}
.team-member .role {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  color: var(--light-grey-text);
  margin-bottom: 0px;
}
.table-responsive tr {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: var(--light-grey-text);
}
.card a {
  color: var(--secondary-text);
}
.card a:hover {
  color: var(--white-text);
}

/*----Programmer page--------*/
.programHdr {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.proh1 h2 {
  font-size: 22px;
}
.proh1 {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  .proh1 h2 {
    font-size: 20px;
  }
}
.guesTbleHeading h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.SatffImgss {
  display: flex;
  cursor: pointer;
  height: 240px !important;
}
.SatffImgss:hover {
  display: flex;
  cursor: pointer;
  height: 240px !important;
}
