@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  background-color: var(--secondary-color);
}
/*-/* Carasusall img section */
.heroS2 {
  position: relative;
  display: flex;
  flex-direction: column;

  .imgCarausal {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 1s ease-in-out;
      z-index: 1;

      &.visible {
        opacity: 1;
        z-index: 2;
      }
    }
  }
}

@media (max-width: 700px) {
  .contNum {
    flex-shrink: 1;
  }
}

/* Our vision  and mission section   */

.visionImage img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  height: 2rem;
  width: 2rem;
}
.missionImage {
  height: 300px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.visionImage {
  flex: 1;
  text-align: center;
}
.visionImage img {
  height: 17.5rem;
  width: 28rem;
}

/* Our Vision and Mission  */
.ourMission {
  padding: 0px !important;
}
.ourVision,
.ourMission {
  flex: 1;
  margin-top: 10px;
  padding: 10px;
}
.ourMission h2,
.newsSection h2,
.ourVision h2 {
  color: var(--primary-color);
  font-size: 22px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}
.newsSection p {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.ourMission p,
.ourVision p {
  color: #333333;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

/* Hero Slider */
.hero-slider {
  position: relative;
  overflow: hidden;
}

.hero-slider img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.hero-slider img.visible {
  opacity: 1;
}

/* Center Section */
.centerSection {
  display: flex;
  position: relative;

  align-items: center;
  margin-top: 10px;
  padding: 10px 0;

  /* background-color: var(--lightGrey-bg); */
}
.Ne {
  display: flex;
  justify-content: center;

  padding: 10px;
}

.newsSection {
  width: 20rem;
  margin-left: 10px;
  margin-right: 10px;
  height: 22rem;
  border-radius: 12px;
  text-align: center;
  border-bottom: 1.5px solid var(--primary-color);
  padding: 2rem;
  background-color: var(--white-text);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  animation: slideRight 0.9s ease-out forwards;
  opacity: 0;
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.visionImage img {
  animation: slideLeft 1.5s ease-out forwards;
  opacity: 0;
}
@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/*----Traning and new */
.News_Tranng {
  position: relative;
  margin-top: 10px;
  padding-bottom: 7rem;
  /* background-color: var(--lightGrey-bg); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 1000px) {
  .News_Tranng {
    margin-top: 10px;
    padding-bottom: 3.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .BlogFaciliteSlider,
  .swiper-slide {
    display: flex;
    flex-direction: column;

    text-align: center;
  }
  .centerSection {
    flex-direction: column;
  }
  .heroS2 .imgCarausal {
    height: 35rem;
    width: 100%;
  }
}
.blog-slider {
  width: 95%;
  position: relative;
  max-width: 800px;
  background: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 15px;
  height: auto;
  transition: all 0.3s;

  overflow-x: hidden;
}

.blog-slider__item {
  display: flex;
  align-items: center;
}

.blog-slider__item .blog-slider__content {
  padding: 0 25px;
}

.blog-slider__img {
  width: 300px;
  flex-shrink: 0;
  height: 300px;
  box-shadow: rgba(255, 0, 0, 0.04) 0px 3px 5px;

  border-radius: 20px;
  overflow: hidden;
}

.blog-slider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-slider__content {
  padding-right: 25px;
}

.blog-slider__code {
  color: var(--primary-color);
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}

.blog-slider__title {
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--primary-text);
}

.blog-slider__pagination {
  position: absolute;
  z-index: 21;
  right: 20px;
  width: 11px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

/*----Facilites---*/
.FacilitesText h1 {
  font-size: 34px;
  padding-top: 20px;
  color: var(--primary-color);
  font-family: "Montserrat", sans-serif;
}
/*--Lef Right Arrow--*/
.ArowsFac button,
.ArowsFac button img {
  background-color: transparent;
  border: none;
  height: 19px;
}
.LeftArr {
  top: 10rem;
  z-index: 100;
  left: -2px;
  position: absolute;
}
.RightArr {
  top: 10rem;
  right: -1px;
  position: absolute;
  z-index: 100;
}

@media screen and (max-width: 1000px) {
  .blog-slider {
    max-width: 680px;
  }
  .blog-slider__img {
    height: 13rem;
    width: 18rem;
  }
  .RightArr,
  .LeftArr {
    top: 9.5rem;
  }
}
@media (min-width: 461px) and (max-width: 999px) {
  .blog-slider {
    max-height: 20rem;
  }
  .blog-slider__img {
    height: 6rem;
    width: 9rem;
  }
  .heroS2 .imgCarausal {
    height: 28rem;
    width: 100%;
  }
}
@media screen and (max-width: 465px) {
  .blog-slider {
    max-height: 250px;
  }
  .blog-slider__img {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .heroS2 .imgCarausal {
    height: 15rem;
    width: 100%;
  }
  .blog-slider__title {
    font-size: 9px;
  }
  .RightArr,
  .LeftArr {
    top: 7.5rem;
  }
  .blog-slider__code {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .ourMission p,
  .ourVision p {
    font-size: 13px;
  }
  .missionImage {
    margin-bottom: 2rem;
  }
  .visionImage {
    margin-top: 15px;
  }
  .visionImage img {
    object-fit: cover;
    height: 13rem;
    width: 91%;
  }
}

/*----news-----*/
