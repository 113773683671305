/* Footer styles */
.footer {
  background-color: var(--primary-color);
  color: #fff;
  position: relative;
}
.pg-footer {
  font-weight: 500;
  font-size: 14px;

  font-family: "Montserrat", sans-serif;
}

.footer-wave-svg {
  display: block;
  position: relative;
  width: 100%;
  height: 30px;
  background-color: transparent;
  top: -1px;
}

.footer-wave-path {
  fill: var(--secondary-color);
}

.footer-content {
  margin: 0 auto;
  max-width: 1230px;
  padding: 40px 15px 450px;
  position: relative;
}

.footer-content-column {
  font-size: 13px;
  box-sizing: border-box;
  float: left;
  width: 100%;

  color: var(--white-text);
}

.footer-content-column ul li a {
  color: var(--white-text);
}

.footer-logo-link {
  display: inline-block;
}
.footer-logo img {
  height: 6rem;
  width: 7.5rem;
}

.footer-menu {
  margin-top: 30px;
}

.footer-menu-name {
  color: #fffff2;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 18px;
  margin: 0;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
.footer-menu-name a {
  color: #fffff2;
}

.footer-menu-list {
  list-style: none;
  padding-left: 0;
}
.fottrrrrrr {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer-menu-list li {
  margin-top: 5px;
  font-family: "Montserrat", sans-serif;
}

.footer-call-to-action-description {
  color: var(--white-text);
}

.footer-call-to-action,
.footer-call-to-action-link-wrapper {
  margin-top: 30px;
}

.footer-social-links {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 236px;
  height: 54px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer-social-amoeba-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 236px;
  height: 54px;
}

.footer-social-amoeba-path {
  fill: #027b9a;
}

.footer-social-link {
  display: block;
  position: absolute;
  padding: 10px;
}
.RTIbtn {
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  border: none;
  background-color: transparent;
  color: var(--white-text);
}

.hidden-link-text {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  top: 50%;
}

.footer-social-icon-svg {
  display: block;
}

.footer-social-icon-path {
  fill: #fffff2;
  transition: fill 0.2s;
}

.footer-copyright {
  background-color: var(--footercopy-right);
  color: #fff;
  padding: 10px 13px;
  text-align: center;
}

.footer-copyright-wrapper {
  margin: 0 auto;
  max-width: 1200px;
}

.footer-copyright-text,
.footer-copyright-link {
  color: var(--white-text);
  font-size: 13px;
  text-decoration: none;
}

/* Media Queries */
@media (min-width: 760px) {
  .footer-content {
    padding-bottom: 18rem;
  }

  .footer-wave-svg {
    height: 50px;
  }

  .footer-content-column {
    width: 20%;
  }
}

.intsaGramIcon {
  position: relative;
  left: 11.1rem;
  top: 0.5rem;
}
.intsaGramIcon:hover,
.LinkDinIcon:hover,
.FacbookIcon:hover,
.YoutubeIcon:hover {
  transform: scale(1.1);
  filter: brightness(120%);
}
.LinkDinIcon {
  position: relative;
  left: 3.6rem;
  bottom: 0.2rem;
}
.FacbookIcon {
  position: relative;
  right: 0.1rem;
  top: 0.3rem;
}
.YoutubeIcon {
  position: relative;
  left: 7.7rem;
  top: 0.4rem;
}
.LinkDinIcon img,
.FacbookIcon img {
  position: absolute;
  height: 2rem;
  width: 2.1rem;
}
.YoutubeIcon img {
  position: absolute;
  height: 2rem;
  width: 1.6rem;
}
.intsaGramIcon img {
  position: absolute;
  height: 1.7rem;
  width: 1.4rem;
}
.AddMap {
  position: absolute;
  bottom: 4rem;
  right: 5px;
}
.small-map {
  width: 310px;
  height: 180px;
}
.nOoFVisitor {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}

@media screen and (max-width: 1000px) {
  .footer-content-column {
    flex-direction: column;
  }

  .small-map {
    display: flex;
    justify-content: center;
    height: auto;
    width: 210px;
  }
  .AddMap {
    display: none;
  }
}

ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}
@media screen and (max-width: 460px) {
  .footer-copyright-wrapper a {
    font-size: 11px;
  }
}
.RTIBTNN {
  background-color: transparent;
  border: none;
  color: var(--white-text);
  font-weight: 500;
}
.RTIBTNN:hover {
  border-bottom: 1px solid var(--white-text);
}

.span_counter {
  display: inline-block;
  padding: 6px 8px;
  border-radius: 3px;
  background: #ffffff;
  margin-right: 3px;
  min-width: 24px;
}
.center_counter {
  color: var(--primary-color);
}
.message_counter {
  color: #ffffff;
  font-family: Verdana;
  font-size: 12px;
  margin-top: 4px;
}
